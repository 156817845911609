export enum statusSubmission {
    DRAFT_STATUS = 1,
    WAITING_SIGN_STATUS = 2,
    WAITING_APPROVE_STATUS = 3,
    CANCELED_STATUS = 4,
    REFUSE_SIGN_STATUS = 5,
    REFUSE_STATUS = 6,
    OUT_OF_DATE_STATUS = 7,
    DONE_STATUS = 8,
    PENDING_CREATE_FILE_STATUS = 9,
    WAITING_APPROVE_EXPIRE_STATUS = 10,
    EXPIRE_STATUS = 11,
    PENDING_SIGN_STATUS = 12,
};
