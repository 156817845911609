import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";
import { FormattedMessage } from "react-intl";

export const EMPTY_DATA_SEARCH_SIGN_TEMPLATE = {
    text_search: "",
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}

export const SIGN_DATE = {
    SERVER: 1,
    CLIENT: 2
}


export const LIST_TYPE_SIGN_TEMPLATE = [
    { name: <FormattedMessage id="digital-signing"/>, code: 1, color: 'success' },
    { name: <FormattedMessage id="e-sign"/>, code: 2, color: 'primary' },
];

export const OPTION_DATE = [
    { name: <FormattedMessage id="date-format-slash"/>, code: 1 },
    { name: <FormattedMessage id="date-format-minus"/>, code: 2 },
];


export const LIST_FONT_SIZE = [
    { name: <FormattedMessage id="common.small"/>, code: 12 },
    { name: <FormattedMessage id="common.average"/>, code: 14 },
    { name: <FormattedMessage id="common.large"/>, code: 16 },
];

export const ARR_SIGN_DATE_OPTION = [
    { name: <FormattedMessage id="SignTemplate.time.system"/>, code: 1 },
    { name: <FormattedMessage id="SignTemplate.time.user"/>, code: 2 },
];

