export const common = {
    // AppTopbarHomePage
    'digital-sign-check': 'Signature verification',
    'plugin-down': 'Plugin download',
    'search-doc': 'Document search',
    app: 'App',
    'common.create_ticket_support': 'Submit a support request',

    //CategoryContractType
    'list-type-contract': 'Contract type',
    'code-name-contracttype': 'Code, name of contract types',
    'code-contracttype': 'Code contract type',
    'name-contracttype': 'Name contract type',
    'procedure-sign': 'Procedure sign',
    'ans-yes': 'Yes',
    'ans-no': 'No',
    description: 'Description',
    'create-time': 'Create time',
    'add-contracttype': 'Add new contract type',
    'update-contracttype': 'Update contract type',
    'view-contracttype': 'View detail',
    'typename-contracttype': 'Type name of contract type',
    'type-description': 'Type description',
    cancel: 'Cancel',
    update: 'Update',
    save: 'Save',
    'del-contracttype': 'Delete contract type',
    'del-contracttype-confirm': 'Are you sure you want to delete the contract type',
    'del-contracttype-multiconfirm': 'Sure to delete multi contract types',
    contract_type_create: 'Add new contract type',
    'input-contracttype-name': 'Type process name',
    categorize: 'Categorize',
    'type-select': 'Select type',
    'sign-by-procedure': "Sign by my organization's procedure",
    'step-name': 'Step name',
    'step-name-empty': 'Step name field is required!',
    'type-confirm-empty': 'Confirm type field is required!',
    'time-sign': 'Signing time (day)',
    'one-day': 'One day',
    'five-day': 'Five days',
    'nine-day': 'Nine days',
    unlimited: 'Unlimited',
    'time-approve-empty': 'Signing time field is required!',
    'submit-type': 'Submit type',
    'submit-only': 'Submit only',
    'submit-sign': 'Submit and sign',
    'common.approve-only': 'Approve only',
    'common.approve-sign': 'Approve and sign',
    'common.sign-only': 'Sign only',
    'approve-account': 'Approve account',
    'select-approve-account': 'Select',
    'approve-account-empty': 'Approve account field is required!',
    'TypeContract.type_confirm_invalid': 'Submit type invalid!',
    'can-change-signer': 'Able to change signer',
    approver: 'Approver',
    'select-approver': 'Select approver',
    'approver-empty': 'Approver field is required!',
    'approve-group': 'Approve group',
    'select-approve-group': 'Select approve group',
    'approve-group-empty': 'Approve group field is required!',
    'number-approved': 'Number of people approved',
    'some-people': 'Some people',
    quantity: 'Quantity',
    'type-quantity': 'Type number of approver',
    'quantity-empty': 'Number of approver field is required and greater than zero!',
    'add-step': 'Add step',
    'list-step-procedure': 'List of steps in signing procedure',
    'step-in-procedure-sign': 'Steps in signing procedure',
    step: 'Step',
    'sure-delete-canvas': 'Are you sure to delete canvas?',

    //CategoryDocument
    'manage-docsample': 'Documentation samples management',
    'code-name-docsample': 'Code, name of documentation sample',
    'select-doctype': 'Select documentation type',
    'code-docsample': 'Code of documentation sample',
    'name-docsample': 'The documentation sample name',
    'del-docsample-multiconfirm': 'Are you sure to delete multi documentation samples',
    'del-docsample-confirm': 'Are you sure to delete documentation sample',
    'add-supplier-sample': "Add with supplier's sample",
    'doc-info': 'Document infomation',
    'select-docsample': 'Select document sample',
    'del-docsample': 'Delete document sample',
    'info-docsample': 'Document template information',
    next: 'Next',
    'add-newsample-enterprise': 'Add new sample of enterprise',
    'cate-document-view-document': 'View document',
    'doc-type': 'Document type',

    //CategoryDocumentType
    'list-type-doc': 'List of documentation types',
    'code-name-doctype': 'Code, name of documentation types',
    'code-doctype': 'Code of documentation types',
    'name-doctype': 'Name of documentation types',
    'add-doctype': 'Add new documentation type',
    'update-doctype': 'Update documentation type',
    'typename-doctype': 'Type name of documentation type',
    'del-doctype': 'Delete documentation type',
    'del-doctype-confirm': 'Are you sure you want to delete the document type',
    'del-doctype-multiconfirm': 'Are you sure to delete multi documentation types',
    'del-process-type': 'Are you sure to delete process type',
    'del-process-type-list': 'Are you sure to delete selected process type list',

    //CategoryPosition
    'title-name': 'Name position',

    //CategoryProcedure
    'name-code-procedure': 'Name or code of process',
    'code-procedure': 'Process code',
    'name-procedure': 'Process name',
    'object-approve': 'Approved object',
    'select-proceduretype': 'Select process type',
    permission: 'Permission',

    //CategoryProcedureAdd
    process_create: 'Create new process',
    'input-procedure-name': 'Type process name',
    'page-numbering': 'Document numbering',
    'time-approve': 'Approve time',
    'approve-type': 'Approve type',
    'select-approve-type': 'Select approve type',
    'approve-type-empty': 'Approve type field is required.',

    //categorysubmission
    submission: 'Submission',
    'sign-manage': 'Text signing',
    'search-by-title': 'Search by title submit',
    'select-procedure': 'Select procedure',
    'select-status': 'Select status',
    'numbering-status': 'Classify',
    from: 'From',
    'search-from': 'Search from create date',
    to: 'to',
    'to-date': 'To date',
    'title-submit': 'Title',
    'procedure-name': 'Process name',
    'date-start': 'Start date',
    'end-date': 'End date',
    'date-complete': 'Complete date',
    task: 'Task',
    'show-from': 'Show from',
    sum: 'Sum',
    record: 'records',
    draft: 'Draft',
    'not-your-turn': 'Not your turn',
    'waiting-turn': 'Waiting turn',
    'waiting-sign': 'Waiting sign',
    'waiting-approve': 'Waiting approve',
    pause: 'Pause',
    'refuse-sign': 'Refuse to sign',
    'refuse-review': 'Refuse to review',
    cancelled: 'Cancelled',
    complete: 'Complete',
    'click-detail': 'Click to view detail',
    edit: 'Edit',
    number: 'Number',
    submit: 'Submit',
    batch_signer: 'Batch Signer',
    'add-quick-signer': 'Create new Quick Signer',
    'update-quick-signer': 'Update Quick Signer',
    isnumbering: 'Numbering',
    nonumbering: 'No numbering',
    numbered: 'Numbered',
    'type-submission': 'Type submission',
    submitbyprocedure: 'Submit by procedure',
    quicksubmit: 'Quick submit',
    signing_process: 'Signing process',
    'resend-doc': 'Resend document',
    'send-doc': 'Send document',
    'export-excel': 'Export Excel',
    'export-data': 'Export Data',
    export: 'Export',
    'type-file': 'Type of file',
    //CategorySubmissionAdd
    submission_creat_new: 'Create new submit document',
    // "batch_signer_creat_new": "Create a new batch signer",
    'type-title': 'Type title',
    'type-title-submit': 'Type submit title',
    'procedure-sample': 'Procedure sample',
    'select-procedure-sample': 'Select procedure sample',
    'doc-submit': 'Submit document',
    'select-submit-doc': 'Select submit document',
    'select-doc': 'Select document',
    'select-attach-doc': 'Select attach document',
    'sent-doc': 'Sent document',
    'attach-doc': 'Attach file',
    'list-doc': 'Document list',
    'list-doc-attach': 'Attach document list',
    'attach-doc-noca': 'Attach document (no CA)',
    'action-after-submit-title': 'Action after completion',
    'action-after-submit': 'Send a copy to a party not participating in the signing flow',
    'action-select-user': 'Business user',
    'send-email': 'Send email:',
    'select-user': 'Select user',
    'send-email-outside': 'Send email outside system',
    'type-address-enter': 'Type email address and press enter to add',
    'set-position-ca': 'Set position of CA',
    'create-submission': 'Create submission',
    'set-signature-position': 'Set signature position',
    'set-external-partners': 'External partners',

    //CategoryQuickSubmissionAdd
    'email-is-not-formatted-correctly': 'Email is not formatted correctly',
    'document-does-not-need-to-be-positioned': 'Document does not need to be positioned',
    'not-need-locate-doc': 'The document does not need to be located',
    'create-new-quick-signer': 'Create new quick signer',
    'not-blank-participant': 'Participants field is required',
    'not-blank-time-type': 'Time type field is required',
    'choose-manager': 'Choose manager',
    'not-blank-manager': 'Manager field is required',
    'enter-partner-name': 'Enter partner name',
    'enter-email': 'Enter email',
    'not-blank-email': 'Email field is required',

    //CategorySubmissionConfirm
    'list-approval': 'Approval list',
    refuse: 'Refuse',

    //CategorySubmissionConfirmed
    'list-doc-approved': 'List of approved texts',
    'title-doc': 'Title of text',

    //CategorySubmitted
    'list-refused-doc': 'List of refused document, out of date document',

    //CategorySubmissionView
    submission_info: 'Submission information',
    quick_submission_info: 'Quick submission information',
    code: 'Code',
    procedure: 'Procedure',
    'procedure-numbering': 'This procedure has page numbering',
    'confirm-BCT': 'Confirm E contract of Ministry of Industry and Trade',
    'has-check': 'Check',
    'no-check': 'No check',
    'mail-finish': 'Mail after finishing submission',
    'list-doc-submission': 'List of submitted document',
    'set-position': 'Set signing position',
    'down-all-doc': 'Download all document',
    'down-all-file': 'Download all file',
    'list-attach-doc': 'List of attached document',
    'step-info': 'Information of steps',
    'participants-list': 'List of participants',
    'information-title': 'Information',
    'note-info': 'Note',
    'signer-title': 'Signer title',
    'confirmed-status': 'Status',
    'create-date': 'Create date',
    'update-date': 'Update date',
    'start-date': 'Start date',
    'complete-date': 'Complete date',
    'download-all-doc': 'Download all documents',
    'back-btn': 'Back',

    // ListFilePreview
    'see-doc': 'Xem',
    'check-CKS': 'Check DS',
    'download-doc': 'Download',
    'view-sign-location': 'View sign location',
    'view-location': 'View location',
    'view-file-detail': 'View file detail',
    'view-file-signing-location-details': 'View file signing location details',

    // ViewProcedureDetail
    'produre-detail_internal': 'Internal',
    'produre-detail_external-partners': 'External partners',
    'signer/approver': 'Signer/Approver',
    'group_signer/approver': 'Group Sign/Approver',
    'signing/approval-date': 'Signing/Approval date',
    'have-sent-one-message': 'Have sent message',
    'produre-detail_participating-parties': 'Participating parties',
    'produre-detail_step-name': 'Step name',
    'produre-detail_approval-time': 'Approval time',
    'confirm-type': 'Confirmation type',
    'only-browse': 'Browse only',
    'browse-and-sign': 'Browse and sign',
    'type of approval': 'Type of approval',
    'produre-detail_user': 'User',
    'produre-detail_title': 'Title',
    'produre-detail_approver': 'Approver',
    'produre-detail_approval-group': 'Approval group',
    'number-of-people-registered': 'Number of people registered',
    'produre-detail_all': 'All',
    'produre-detail_some-people': 'Some people',
    'produre-detail_quantity': 'Quantity',
    'produre-detail_manager': 'Manager',
    'produre-detail_description': 'Description',
    'reason-for-refusal': 'Reason for refusal',
    'produre-detail_message': 'Message',

    //CategoryTypeProcedure
    'no-record': 'No record!',
    'code-type-procedure': 'Code',
    'name-type-procedure': 'Name type of process',
    'type-namecode-proceduretype': 'Type code or name of process type',
    'add-proceduretype': 'Add type of process',
    'update-proceduretype': 'Update type of process',
    'type-name-procedure': 'Enter name type of process',

    // DialogSendDoc
    'document-title': 'Document title',
    'choose-partner': 'Choose partner',
    'enter-message': 'Enter message',

    //ContractManagement
    'contract-management': 'Contract signer',
    'search-contract-by-title': 'Name, code or contract number ',
    'check-bct': 'Check the Ministry of Industry and Trade',
    'title-contract': 'Contract title',
    'type-title-contract': 'Type contract title',
    'number-contract': 'Contract number ',
    'type-number-contract': 'Type contract number',
    'search-from-effect': 'Search from effective date',
    'out-of-date': 'Out of date',
    pending_create_file: 'Pending',
    expiring: 'Expiring',
    expire: 'Expire',
    effect: 'Effect',
    contract_create_new: 'Create contract',
    'type-contract': 'Contract type',
    'select-type-contract': 'Select type contract',
    'select-contract-doc': 'Select contract document',
    'doc-contract': 'Contract document',
    'doc-contract-ca': 'Contract document (will digital sign)',
    'code-contract': 'Contract code',
    'list-contract': 'Contract list',
    'list-contract-sub': 'Contract list submission',
    continue: 'Continue',
    'validity-of-contract': 'Validity of contract',
    contract_update: 'Update contract',
    'add-participants': 'Add participants',
    'save-and-exit': 'Save and exit',
    'come-back': 'Back',
    'send-contract': 'Send contract',
    'resend-contract': 'Resend contract',
    'save-position': 'Save position',
    'contract-management-confirm': 'Approve contract',
    'participating-parties': 'Participating parties',
    'create-contract': 'Create contract',
    'Contract.change_type_contract': 'Contract type has changed, do you want to update?',
    'Contract.detail_type_contract': 'Contract type details',
    'Contract.contract_has_change': 'The type of contract has changed',
    'Contract.contract-management-confirmed.title': 'Contract approved',
    'Contract.contract-management-refuse.title': 'Contract REFUSED AND EXPIRED',

    // ContractManagementAdd
    'line-before': 'The contract is valid for',
    'line-1-after-case-1-month': 'month and takes effect starting from the date of completion of signing',
    'line-1-after-case-many-month': 'months and takes effect starting from the date of completion of signing',
    'line-2-after-case-1-month': 'month and takes effect starting on',
    'line-2-after-case-many-month': 'months and takes effect starting on',
    'line-3': 'The contract takes effect starting on',
    'Contract.label_expire_time': 'and contract validity until date',

    // ContractManagementDelete
    'delete-contract-header': 'Delete contract',
    'delete-contract-before': 'Are you sure to delete the',
    'delete-contract-after': 'contract',
    'delete-list-contract': 'Do you want to delete the selected list of contracts?',

    // ContractManagementView
    'contract-info': 'Contract information',
    'contract-type': 'Type',
    'valid-contract': 'The contract is valid for',
    'month-from-contract-1': 'month from',
    'month-from-contract-many': 'months from',
    'month-to-contract': 'to',
    'send-copy-contract': 'Once completed send a copy to the parties involved',
    'send-type': 'Send type',
    'start-from': 'start from',

    // ViewTypeContractDetail
    'internal-account': 'Internal account',
    'organizational-partner': 'Organizational partner',
    'personal-partner': 'Personal partner',
    'my-organization': 'My organization',
    'choose-party-to-join': 'Choose party to join',
    'signing-deadline': 'Signing deadline',
    'choose-signing-deadline': 'Choose signing deadline',
    'participating-party': 'Participating party',
    representative: 'Representative',
    'organization-name': 'Organization name (if any)',

    // ContactManagementResend
    'sure-to-resend-before': 'Are you sure to resend',
    'sure-to-resend-after': 'contract',

    //CheckSign
    'box-click': 'Drop file into this box',
    'for-check': 'For checking digital sign',
    'for-sign': 'For digital signing',
    or: 'or',
    'file-select': 'Select file',

    //CheckSignDigital
    'upload-guide': "(Support 'pdf', 'ppt', 'pptx', 'doc', 'docx', 'xls', 'xlsx' file )",
    'upload-guide-sign': '(Support to upload .pdf format)',
    'doc-list': 'Document list',
    'doc-list-permissions': 'List file permissions',
    'doc-submit-ca': 'Submit document (will digital sign)',
    'location-sign-not-exist': 'Sign location does not exist!',

    // ViewPdfPositionSignContract
    'list-step': 'List of steps',
    'sign-location': 'Sign location',

    //Common
    'title-c': 'Title', // chức danh
    title: 'Title',
    'digital-sign': 'Digital sign',
    cts: 'Digital signatures',
    signature: 'Signature',
    data: 'Data',
    login: 'Login',
    'account-manage': 'My account',
    logout: 'Logout',
    note: 'Note',
    'type-note': 'Type note',
    user: 'User',
    'user-list': 'User list',
    'user-empty': 'User field is required.',
    position: 'Position',
    'position-empty': 'Position field is required.',
    empty: 'Empty',
    all: 'All',
    'not-blank': 'is required.',
    account: 'Account',
    'external-account': 'External account',
    manager: 'Manager',
    receiver: 'Receiver',
    name: 'Name',
    'type-name': 'Type name',
    role: 'Role',
    upgrade: 'Upgrade',
    'skip-upgrade': 'Skip upgrade',
    person: 'Person',
    no: 'No',
    enter: 'Enter',
    time: 'Time',
    executor: 'Executor',
    search: 'Search',
    add: 'Add',
    create: 'Create',
    retype: 'Retype',
    sort: 'Sort',
    convert: 'Convert',
    delete: 'Delete',
    remove: 'Remove',
    copy: 'Copy',
    hide: 'Hide',
    'copy-success': 'Copy successfully',
    select: 'Select',
    view: 'View',
    home: 'Home',
    created_by: 'Created by',
    created_at: 'Created at',
    updated_by: 'Updated by',
    updated_at: 'Updated at',
    action: 'Action',
    status: 'Status',
    close: 'Close',
    want: 'want',
    download: 'Download',
    contract: 'Contract',
    'code-document': 'Document code',
    'import-excel': 'Import Excel',
    'download-sample-excel': 'Download sample file',
    'select-excel-file': 'Select excel file',
    confirm: 'Confirm',
    support: 'Support',
    'lock-reason': 'Lock reason',
    'no-result': 'No results found',
    content: 'Content',
    'sign-for-approval': 'Approve/ Sign',
    closepopup: 'Close',
    hour: 'Hour(s)',
    date: 'Date',
    day: 'Day(s)',
    send: 'Send',
    message: 'Message',
    time_at: 'at',
    page: 'Page',
    browse: 'Browse',
    accept: 'Accept',
    retry: 'Retry',
    approve: 'Approve',
    existed: 'Existed',
    choose: 'Choose',
    preview: 'Preview',
    'create-doc': 'Create document',
    partner: 'Partner',
    'general-doc': 'General document',
    'delete-all': 'Delete all',
    new: 'New',
    using: 'Using',
    used: 'Used',
    'internal-partner': 'Internal partner',
    'external-partner': 'External partner',
    parameter: 'Parameter',
    'upload-doc-sample': 'Upload sample',
    'edit-document': 'Edit document',
    list: ' List',
    'sure-to': 'Are you sure to',
    'incorrect-format': 'is incorrect format',
    'over-capacity': 'over capacity',
    staff: 'Staff',
    'customer/partner': 'Customer/Partner',
    process: 'Process',
    personal: 'Personal',
    'switch-organization': 'Switch organization',
    'list-organization': 'Organization list',
    'organization-title-change': 'Change Organization',
    'organization-warning-change': 'Are you sure to change organization?',
    'organization-button-change': 'Change',
    capacity: 'Capacity',
    activities: 'Activities',
    'activities-history': 'Activities history',
    detail: 'Detail',
    loading: 'Loading...',
    'field-is-required': 'field is required!',
    restore: 'Restore',
    invite: 'Invite',

    //ConfigDigitalCertificate
    'name-remember-nonote': 'Reminiscent name',
    'name-remember': 'Reminiscent name (self creating)',
    'view-ca-info': 'View digital signature information',
    'common.add.new_remote_sign': 'Add new remote digital signature',
    'common.add.new_signatures_token': 'Add new Token digital signature',
    'cant-view-info': 'You cannot view this information',
    app_id: 'Account',
    secret_code: 'Password',
    'common.remote_sign': 'Remote digital signature',

    //ConfigStorage
    'config-storage-title': 'setting file config storage',
    'config-storage-save-storage': 'Current storage location',
    'config-storage-personal': 'Personal',
    'config-storage-info-save-storage-personal': 'Personal storage information',
    'config-storage-current-storage-type': 'Current storage type',
    'config-storage-info-storage-personal': 'Personal storage setting',
    'config-storage-google-client-id': 'google_client_id',
    'config-storage-google-client-secret': 'google_client_secret',
    'config-storage-google-folder-id': 'google_folder_id',
    'config-storage-google-refresh-token': 'google_refresh_token',
    'config-storage-dropbox-token': 'Dropbox Token',

    //dashboard-navbar
    'data-collection': 'Data Collection',
    'personal-doc': 'Personal document',
    'data-total': 'Total document',
    'data-pending': 'Pending document',
    'data-reject': 'Rejected document',
    'doc-done': 'Approved document',
    'doc-enterprise': 'Enterprise document',
    'enterprise-servicepackage-info': 'Enterprise Service Package Information',
    'upgrade-servicepackage': 'Upgrade Service Package',
    'number-user': 'Number of active users/ Limit users by service plan',
    'number-procedure': 'Number of processes created/Number of possible processes',
    'number-doc-monthly': 'Number of documents, contract used / Number of documents, contract that can be added',
    'storage-capa': 'Storage used / Total capacity according to service plan',
    'chartone-title': 'The changes of enterprise document in ',
    'doc-topbar': 'Document',
    'submit-topbar': 'Submit document',
    'approve-topbar': 'Approve document',
    'completed-topbar': 'Completed document',
    'refused-topbar': 'Refused and expired',
    'incoming-topbar': 'Incoming document',
    'outgoing-topbar': 'Outgoing document',
    'archives-topbar': 'Archives',
    'personal-doc-topbar': 'Personal document',
    'type-doc-topbar': 'Type of document',
    'utilities-topbar': 'Utilities',
    'sample-doc': 'E-forms',
    'sample-doc_create': 'Document template',
    organization: 'Organization',
    'contract-manager-topbar': 'Contract',
    'contract-topbar': 'Contract signer',
    'template-contract-topbar': 'Type of contract',
    'type-processes': 'Type of processes',
    'type-process': 'Type of process',
    'select-type-process': 'Select type of process',
    'type-procedure-list': 'Type list of procedure',
    'processes-topbar': 'Processes',
    'manage-organizationtree-topbar': 'Manage organization tree',
    'sample-email-topbar': 'Email templates',
    'sample-sign-topbar': 'Signatures',
    'manage-position-topbar': 'Positions',
    'setting-cts-topbar': 'Configure digital signature',
    'partner-topbar': 'Partners',
    'system-topbar': 'System',
    'manage-role-topbar': 'Roles',
    'manage-user-topbar': 'Users',
    'system-setting-topbar': 'System setting',
    'filestorage-setting-topbar': 'Storage setting',
    'mail-history-topbar': 'Mailing logs',
    'user-manual-topbar': 'User manual',
    'down-plugin-usb': 'Download USB plugin',
    'setup-wizard': 'Quick setup',
    'software-ver': 'Software version',
    'quick-sign': 'Quick sign',
    'charttwo-title': 'Partner of Business',
    hotline: 'Hotline',
    version: 'Version',
    lang: 'Language',
    'contract-approve-topbar': 'Approve contract',
    'this-contract-confirm': 'confirm this contract',
    'contract-complaints': 'Contract complaints',
    'code-complaints': 'Code complaints',
    'complaint-title': 'Complaint title',
    'complaint-content': 'Complaint content',
    'complaint-time': 'Complaint time',
    'contract-name': 'Contract name',
    contract_complaints_create: 'Create a new contract complaint',
    contract_complaints_view: 'View contract complaint details',
    'complaints-type': 'Complaints type',
    'send-complaint': 'Send complaint',
    other: 'Other',
    'contract-signing-time': 'Contract signing time',
    processing: 'Processing',
    processed: 'Processed',
    tax_code_partner_complaint: 'Complaint partner tax code',
    name_partner_complaint: 'Complaint partner name',
    liquidation_agreement: 'Liquidation agreement',

    //DialogCategoryPosition
    'add-position': 'Add new position',
    'list-position': 'List of position',
    'update-position': 'Update position',
    'enter-title-name': 'Type name position',

    //EmailTemplate
    'email-sample': 'email sample',
    'manage-emailsample': 'Email template manage',
    'email-sample-search-text': 'Search by name, title or code',
    'email-sample-code': 'Email template code',
    'email-sample-name': 'Email template name',
    'email-sample-title': 'Email template title',
    'email-sample-content': 'Email content',
    'email-title-upgrade': 'Upgraded email subject',
    'email-content-upgrade': 'Upgraded email content',
    actived: 'Activated',
    deactived: 'Deactivated',
    'not-active-yet': 'Not active yet',
    active: 'Activate',
    deactive: 'Deactivate',
    'email-sample-active': 'Active email template',
    'email-sample-active-message': 'Are you sure to active email template',
    'email-sample-deactivate': 'Deactivate email template',
    'email-sample-deactivate-message': 'Are you sure to deactivate email template',
    'email-sample-update': 'Update email template',
    'email-sample-table-of-note': 'Table of notes',
    'email-sample-param': 'Parameter',
    'email-sample-title_vi': 'Email template Vietnamese title',
    'email-sample-title_en': 'Email template English title',
    'email-sample-title_bilingual': 'Email template bilingual title',
    'email-sample-title_vi_upgrade': 'Upgraded Vietnamese email subject line',
    'email-sample-title_en_upgrade': 'Upgraded English email subject line',
    'email-sample-title_bilingual_upgrade': 'Upgraded bilingual email headers',

    //InputUploadFile
    'select-device': 'Select from device',
    'select-personaldoc': 'Select from personal document',
    'create-fromsample': 'Create document from sample',
    'create-many-fromsample': 'Create multiple documents from templates',

    //LogEmail
    'log-email-manage': 'Manage history mail',
    'log-email-status': 'Status',
    'log-email-send-to': 'Send to',
    'log-email-title': 'Title',
    'log-email-name': 'Name',
    'log-email-code-or-title': 'Code or title',
    'log-email-resend': 'Resend',
    'log-email-view': 'View',
    'log-email-success': 'Success',
    'log-email-failed': 'Failed',
    'log-email-receiver': 'Receiver',
    'log-email-title-view': 'View history mail detail',
    'log-email-resend-mail': 'Are you sure to resend mail',
    'log-email-send': 'Send',

    //Login
    password: 'Password',
    'login-fail': 'Login failed',
    'error-occur': 'An error has occurred!',

    //MenubarRight
    notify: 'Notifications',
    'mark-all-read': 'Mark all as read',
    'remove-all-noti': 'Remove all notify',
    'mark-unread': 'Mark as unread',
    'mark-read': 'Mark as read',
    'remove-noti': 'Remove notify',
    'no-noti': "You don't have any notify!",
    'see-more': 'See more',
    'password-change': 'Change password',
    refresh: 'Refresh',

    //MenuFooter
    'office-adress': 'Office: 4B floor, T6-08 Building, Ton Quang Phiet street, Ha Noi.',
    'hotline-email': 'Hotline: 1900.2038 - Email: support@cyberlotus.com',
    product: 'Product',
    'cyberbill-bill': 'CyberBill digital bill',
    'cyberbill-tax': 'CyberTax digital tax',
    'authen-device': 'Authentic device FIDO',
    solution: 'Solution',
    'operation-administration': 'IT operation and administration',
    'authen-ca': 'CA authenticate',
    'sign-biometric-authen': 'Digital sign and biometric authenticate',
    'ca-mobile-authen': 'CA and identification on mobile',
    service: 'Service',
    'system-integration': 'System integration',
    'it-advisory': 'IT advisory',
    'it-administration': 'IT administration',
    'it-training': 'IT training',

    //OrganizationTree
    'parent-level': 'Parent level',
    'delete-record': 'Delete room/department',
    "delete-level-confirm": "Are you sure you want to delete this department/department and its children?",
    'add-new-organization-tree': 'Add new department/department',
    'organization-tree-name': 'Department/department name',
    'organization-tree-list-belong-to': 'List of users belonging to department/department',
    'organization-tree-view-detail': 'View department/department details',
    'update-organization-tree': 'Update department/department',
    'notify-check-again': 'Please re-check the permissions of the process and document template when changing the parent level',
    'selected-list-title-c': 'selected list of positions',

    //Partner
    'partner-add-oz': 'Add new organization partner',
    'partner-add-per': 'Add new personal partner',
    'partner-update-oz': 'Update Business partner',
    'partner-update-per': 'Update personal partner',
    'partner-text-search': 'Fullname or identifier code',
    'partner-identify-code': 'Identifier code',
    'partner-identify-type': 'Identifier type',
    'partner-name': 'Partner name',
    'partner-type': 'Partner type',
    'partner-personal-belong-to-oz': 'Individuals belonging to organizations or businesses',
    'partner-cn-business-name': 'Business name',
    'partner-cn-business-tax-code': 'Tax code',
    'partner-cn-business-position': 'Position',
    'partner-dn-business-position': 'Position',
    'partner-cn-business-email': 'Email',
    'partner-cn-business-phone': 'Phone',
    'partner-cn-business-address': 'Address',
    'partner-cn-name': 'Full name',
    'partner-cn-identify-type': 'Identifier type',
    'partner-cn-identify-code': 'Identifier code',
    'partner-cn-identify-code-id': 'Identifier card number',
    'partner-cn-identify-code-passport': 'Passport',
    'partner-cn-identify-code-citizen': 'Citizen ID number',
    'partner-cn-identify-code-social': 'Social Security Code',
    'partner-cn-identify-issue-date': 'Issue date',
    'partner-cn-identify-place': 'Place of issue',
    'partner-cn-tax-code': 'Tax code',
    'partner-cn-province': 'Province',
    'partner-cn-district': 'District',
    'partner-cn-phone': 'Phone',
    'partner-cn-address': 'Address',
    'partner-cn-if-any': 'if any',
    'partner-cn-info-personal': 'Personal information',
    'partner-message-delete': 'Are you sure to delete partner',
    'partner-message-delete-multi': 'Are you sure to delete partner list ?',
    'partner-header-delete': 'Delete partner',
    'partner-dn-identify-tax-code': 'Tax code',
    'partner-dn-identify-budget-code': 'Budget code',
    'partner-dn-identify-establishment-decision-code': 'Establishment decision number',
    'partner-dn-identify-social-code': 'Social Security Code',
    'partner-dn-identify-investment-code': 'Investment license number',
    'partner-dn-info-business': 'Business information',
    'partner-dn-name': 'Business name',
    'partner-dn-email': 'Email',
    'partner-dn-phone': 'Phone number',
    'partner-dn-address': 'Address',
    'partner-dn-representative': 'Representative',
    'partner-dn-representative-name': 'Representative name',
    'partner-dn-representative-identifier': 'Identifier code',
    connect: 'Connect',
    refuseconnect: 'Refuse connect',
    acceptconnect: 'Accept connect',
    disconnect: 'Disconnect',
    onconnecting: 'Turn on connection',
    'send-request': 'Send request',
    'connect-org': 'Connect organization',
    'refuse-connect-request': 'Refuse connect request',
    'Partner.phone_partner': 'Partner phone',
    'Partner.address_partner': 'Partner address',
    'Partner.tax_code_partner': 'Partner tax_code',
    'Partner.code_oz': 'Business code',
    'Partner.email_oz': 'Business email',
    'Partner.phone_oz': 'Business phone',
    'Partner.address_oz': 'Business address',
    'Partner.position_oz': 'Business position',
    'Partner.c_identifier_date': 'Identifier Issue Date',
    'Partner.c_identifier_place': 'Identifier Place',
    'Partner.c_identifier_provider': 'Identifier Issuer',
    'Partner.c_present_passpost': 'ID number agent',
    'Partner.c_present_passpost_date': 'The date of issue of ID number of the representative',
    'Partner.c_present_passpost_place': 'Place of ID number of Representative',
    'Partner.c_present_fullname': 'Name of the representative',
    'Partner.c_present_position': 'Position of Representative',
    'Partner.c_present_phone': 'Representative phone number',
    'Partner.c_present_email': 'Email of representative',

    //PersonalDocument
    'personal-doc-upper': 'Personal document',
    mydoc: 'My document',
    submitting: 'Using',
    star: 'Star',
    'not-star': 'Not star',
    shared: 'Shared',
    'shared-with-me': 'Shared with me',
    trash: 'Trash',
    'search-file-mydoc': 'Search file in my documents',
    'file-upload': 'Upload file',
    'add-folder': 'Add new folder',
    'change-name-folder': 'Change folder name',
    'folder-name': 'Folder name',
    'type-folder-name': 'Type folder name',
    back: 'Back',
    'existed-foldername': 'Existed folder name',
    sign_documents: 'Sign documents',

    //RoleManage
    'role-search-input-text': 'Enter display name or role name',
    'role-table-name': 'Role name',
    'role-table-display-name': 'Display name',
    'role-table-type-name': 'Role type',
    'role-table-delete': 'Delete role',
    'role-table-message-delete': 'Are you sure to delete role',
    'role-table-message-delete-multi': 'Are you sure to delete role',
    'role-table-add': 'Add new role',
    'role-table-list': 'Role list',
    'role-table-update': 'Update role',
    'role-table-permission': 'Permission',
    'role-table-view-detail': 'View detail role',
    'role-table-view-detail-permission': 'View detail permission',
    'common.role.placeholder_name': 'Enter role name. For example: Salesperson',

    //SignTemplate
    'list-samplesign': 'Signature sample list',
    'code-samplesign': 'Code',
    'name-samplesign': 'Signature sample name',
    'type-samplesign': 'Type',
    'code-name-signsample': 'Code, name of signature sample',
    'image-upload-position': 'Image upload position',

    //SignTemplateDialog
    'view-samplesign': 'View signature sample',
    'add-samplesign': 'Add new signature sample',
    'update-samplesign': 'Update signature sample',
    'type-signature': 'Signature type',
    'select-type-signature': 'Select signature type',
    'show-ca': 'Show signature',
    'info-ca': 'CA information',
    'select-show-info': "Show personal information if don't check!",
    'type-show': 'Show type',
    'image-info': 'Image and information',
    image: 'Image',
    info: 'Information',
    'org-signature': 'Organization signature',
    'org-sample-sign': 'Organization signature sample',
    label: 'Label',
    'time-sign-template': 'Signing time',
    office: 'Office',
    'e-sign': 'E-sign (Image signature)',
    'digital-signing': 'Digital signature (digital signature required)',
    'draw-sign': 'Draw signature',
    draw: 'Draw',
    'select-img': 'Select image from device',
    'name-organization': 'Name of organization',
    signer: 'Signer',
    'name-signer': 'Signer name',
    'name-office': 'Office name',
    'email-address': 'Email address',
    'name-position': 'Position name',
    unit: 'Unit',
    'name-unit': 'Unit name',
    'date-format-slash': 'Day/month/year hour:minute:second',
    'date-format-minus': 'Day-month-year hours:minutes:seconds',
    'font-size': 'Fontsize',
    'setting-font-size': 'Setting fontsize',
    'text-info-template': 'Size displayed when the sign box is the largest size',

    //UserManage
    'view-user-info': 'View user information',
    'user-search-input-text': 'Enter full name or email',
    'user-table-name': 'Full name',
    'user-table-role': 'Role',
    'user-table-email': 'Email',
    'user-table-phone': 'Phone',
    'user-table-position': 'Position',
    'user-table-type-user': 'User type',
    'user-table-usage-capacity': 'Usage capacity',
    'user-table-lock': 'Lock',
    'user-table-un-lock': 'Unlock',
    'user-table-reset-password': 'Reset password',
    'user-table-capacity-adjustment': 'Capacity adjustment',
    'user-not-exist': 'Does not exist',
    'header-reset-password': 'Reset default password',
    'confirm-reset-password': 'Are you sure to reset user password',
    'error-file-import-type': 'is not in the correct format',
    'error-file-import-null': 'You have not selected the file',
    'delete-file-import': 'Delete file',
    'delete-user': 'Delete user',
    'confirm-delete-user': 'Are you sure to delete user',
    'confirm-delete-multi-user': 'Are you sure to delete selected user list',
    'user-organization-tree-list': 'Organization tree list',
    'user-header-add-user': 'Add new user',
    'user-header-invite-user': 'Invite new user',
    'user-header-update-user': 'Update user',
    'user-password': 'Password',
    'user-password-confirm': 'Confirm password',
    'user-password-suggest':
        'Password have to at least 8 characters include at least one lowercase letter one uppercase letter and a number',
    'user-title-update-status': 'Update status',
    'user-message-lock-unlock': 'Are you sure to',
    'user-message-lock-unlock-user': 'user',
    'user-adjustment-title': 'Adjust',
    'user-adjustment-type': 'Adjustment type',
    'user-capacity-used': 'Capacity used',
    'user-capacity': 'Capacity',
    'user-adjustment-increment': 'Increment',
    'user-adjustment-decrease': 'Decrease',
    'identifier-code': 'Identify card',
    not_active_yet: 'Not active yet',
    status_cyberid_removed: 'Account has removed by CyberID',
    'user.status_cybersign_not_authorized': 'Not authorized to use',

    //UserManual
    'user-manual-text-search': 'User manual name',
    'user-manual-title': 'Title',
    'user-manual-type': 'Type',
    'user-manual-date': 'Release Date',
    'user-manual-info': 'Info',
    'table-content': 'Table of content',
    'user-manual-view-detail': 'View detail user manual',

    //Noti
    GET_FAIL: 'Get list failed',
    CREATE_SUCCESS: 'Create success',
    CREATE_FAIL: 'Create failed',
    UPDATE_SUCCESS: 'Update success',
    UPDATE_FAIL: 'Update failed',
    UPDATE_PASS_SUCCESS: 'Reset password success',
    UPDATE_PASS_FAIL: 'Reset password failed',
    DELETE_SUCCESS: 'Delete success',
    DELETE_FAIL: 'Delete failed',
    RESEND_SUCCESS: 'Resend success',
    RESEND_FAIL: 'Resend failed',
    SUCCESS: 'Success',
    FAIL: 'Failed',
    MESSAGE_ERROR: 'Error occurs',
    MESSAGE_ERROR_SEARCH: 'Document not found',
    MESSAGE_ERROR_LOAD: 'Error occurs, refresh page',
    MESSAGE_VALIDATE: 'Check data input',
    SEND_EMAIL_SUCCESS: 'Send mail success',
    SEND_EMAIL_FAIL: 'Send mail failed',
    CHECK_SUCCESS: 'Check success',
    CHECK_FAIL: 'Check failed',
    NOTIFY_READ: 'Read notify',
    NOTIFY_DELETE: 'Remove notify',
    FILE_DOES_NOT_EXIST: 'File not found',
    FILE_UPLOAD_SUCCESS: 'Upload success',
    FILE_TYPE_REQUIRE: 'Type file cannot be empty',
    DATA_EXPORT_REQUIRE: 'Data export cannot be empty',
    //Release notes
    release_note: 'Release notes',

    'additional-information': 'Additional information',
    'click-to-create-info-field': 'Click to create the information fields below to add text or image input boxes',
    'click-to-create-info-sign-field': 'Click to create the information fields below to add sign boxes',
    'letters/numbers': 'Letters/Numbers',
    show: 'Show',
    'procedure-has-doc-numbering': '(This procedure has text numbering)',
    'have-no-manager': 'You have no manager!',
    'orga-signs-by-progress': 'My organization signs by process',

    'you-have': 'You have',
    'new-noti': 'new notifications',

    'send-in-order-party-involved': 'Send in the order of the parties involved',
    'send-contract-all-party': 'Send contract to all parties simultaneously',
    'send-my-orga-first': 'Send it to my organization first',
    initialization: 'Initialization',

    'need-2-party': 'The contract requires two parties',
    'need-1-party': 'The contract requires one party',
    'duplicated-participants': 'Duplicated participants',
    'sign-position-info': 'Signing position information',

    'doc-not-located': 'Document is not located',
    'doc-is-located': 'Document is located',
    'sign-doc': 'Sign document',
    'no-data': 'No data',
    'approve-this-doc': 'approve this document',
    'email-partner': 'Partner email',
    'down-img': 'Download image',
    'contract-status': 'Contract status',
    'complete-time': 'Complete time',
    'user-type': 'User type',
    'email-code': 'Email code',
    'view-info-CKS': 'View DS information',
    'type-procedure': 'Type of procedure',

    'PDF.fail-load-file': 'Failed to load PDF file',
    'PDF.page-current': 'Current page',
    'PDF.first-page': 'First page',
    'PDF.previous-page': 'Previous page',
    'PDF.next-page': 'Next page',
    'PDF.last-page': 'Last page',

    'SIGN.check_BCT': 'Signing confirmation of the Ministry of Industry and Trade',

    'message-not-exceed-1000': 'Message cannot exceed 1000',

    'ChangeLog.Dialog.title': 'New software version release announcement',
    'ChangeLog.Dialog.content': 'CyberLotus announced a software upgrade',
    at: 'at',

    'validate.required': 'The field is required',
    'validate.max_500': 'The field may not be greater than 500 characters!',

    'Notify.action': 'option',

    'Sign.authority': 'Authority',
    'Sign.authority_sign': 'Authorization to sign',
    'Sign.authority_sign_confirm': 'Make sure you want to authorize signing!',
    'Sign.signer': 'Signer',
    'Sign.signer_select': 'Select signer',
    'Sign.auth_to': 'authorization to',
    'Sign.auth_time': 'Authorization time',
    'Sign.auth_message': 'Authorization message',
    'Sign.file_auth': 'Authorization file',
    'Sign.select_file_auth': 'Select the authorization file',
    'Sign.list_file_auth': 'Authorization file list',

    'update-info-cyberid': 'Update information on CyberID',
    report: 'Report',
    synthesis_report: 'Synthesis report',
    total: 'Total',

    'common.are_you_sure': 'Are you sure you want',
    'common.check_sign_all_page': 'Sign all pages',
    'contract-liquidation': 'Liquidation agreement',

    'add-batch-signer': 'Create new batch signer',
    'update-batch-signer': 'Update the batch signer',
    contract_liquidation_management: 'Contract liquidation management',
    type_title_contract_liquidation: 'Type title contract liquidation',
    send_contract_liquidation: 'Send liquidation minutes',
    'send-contract-liquidation-all-party': 'Send liquidation minutes to all parties simultaneously',
    'send-contract-liquidation': 'Send liquidation minutes',
    'resend-contract-liquidation': 'Resend liquidation minutes',
    // ContractManagementDeleteLiquidation
    'delete-contract-liquidation-header': 'Delete the minutes of liquidation',
    'delete-contract-liquidation-before': 'Are you sure you want to delete the liquidation minutes',
    'delete-list-contract-liquidation': 'Are you sure you want to delete the selected liquidation record',

    'common.file_extension': 'File extension',
    'common.list_file': 'List file',

    'common.people': 'People',
    'common.active_status': '(with active status)',

    'common.server': 'Server',
    'common.client': 'Client',

    'common.resend': 'Resend',
    'common.add_lot': 'Add new batch',

    'common.process_create_lot': 'Batch is in progress',
    'common.process_sign': 'Waiting for processing to sign',
    'common.administration': 'Administration',
    'common.signatureManagement': 'Signature management',
    'common.emailManagement': 'Email management',
    'common.serviceManagement': 'Service pack management',

    'common.waitApprove': 'Waiting approve',
    'common.Approved': 'Approved',
    'common.refuseApprove': 'Refuse to review',
    'common.draft': 'Draft',
    'common.completed': 'Completed',

    'common.approve': 'Approve',
    'common.refuse': 'Refuse',
    'common.info': 'Info',
    'common.requestSupport': 'Request for support',
    'common.list': 'List',
    'common.partnerOther': 'Other partner',

    'common.addPackage': 'Buy additional packages',
    'common.addServicePackage': 'Buy additional service packages',

    'common.small': 'Small',
    'common.average': 'Average',
    'common.large': 'Large',
    "common.downCyberSignClient":"Tải xuống CyberSign Client",
    "common.fileIntegration":"Integration file",
    "common.fileIntegrationDetail":"Third-party integration file link",

    "common.numberOfDocumentContract":"number of documents, contract",
    "common.storageCapacity":"storage capacity",
    "common.numberOfUser":"number of users",
    "common.numberOfProcess":"number of processes",

    "common.pleaseExtendService":"Please extend so that the service is not interrupted",
    "common.almostOver":"almost over",
    "common.isOver":"is over",
    "common.notifyCert":"Notice of digital signature deadline",

    "common.digitalSignature":"Digital signature",
    "common.aboutExpire":"is about to expire",
    "common.hasExpired":"has expired",
    "common.expirationDate":"expiration date",

    "common.warning":"Warning",

};
