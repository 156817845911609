import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_CONTRACT_DOCUMENT } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_CONTRACT_DOCUMENT
    }
};

export const contractDocumentSlice = createSlice({
    name: 'contractDocument',
    initialState,
    reducers: {
        searchContractDocument: (state, action) => {
            state.search = action.payload;
        },
        searchContractDocumentPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = contractDocumentSlice;
export const { searchContractDocument, searchContractDocumentPageFirst } = actions

export const selectSearchContractDocument = (state) => state?.features?.contractDocument?.search

export default reducer


