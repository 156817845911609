import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";
import { FormattedMessage } from "react-intl";

export const EMPTY_DATA_SEARCH_CONTRACT_TYPE = {
    text_search: "",
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}

export const ARR_STATUS_TYPE_CONTRACT = [
    { name: <FormattedMessage id="actived"/>, code: 1, color: 'success' },
    { name: <FormattedMessage id="deactived"/>, code: 2, color: 'danger' },
];
