import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";
import { FormattedMessage } from "react-intl";

export const EMPTY_DATA_SEARCH_DIGITAL_CERTIFICATE = {
    text_search: "",
    status: null,
    type: null,
    type_hsm: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}


export const ARR_TYPE_CTS_CERTIFICATE = [
    { code: 1, name: <FormattedMessage id="common.remote_sign" />,color: 'success'},
    { code: 2, name: "Token", color: 'warning' },
];
export const ARR_TYPE_CERTIFICATE = [
    { code: 2, name: <FormattedMessage id="person"/>, color: 'info' },
    { code: 1, name: <FormattedMessage id="organization"/>, color: 'success' },
];
export const ARR_STATUS_CERTIFICATE = [
    { name: <FormattedMessage id="actived"/>, code: 1, color: 'success' },
    { name: <FormattedMessage id="deactived"/>, code: 2, color: 'danger' },
];
