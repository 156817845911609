import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_CATEGORY_REPORT } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_CATEGORY_REPORT
    }
};

export const categoryReportSlice = createSlice({
    name: 'categoryReport',
    initialState,
    reducers: {
        searchCategoryReport: (state, action) => {
            state.search = action.payload;
        },
        searchCategoryReportPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = categoryReportSlice;
export const { searchCategoryReport, searchCategoryReportPageFirst } = actions

export const selectSearchCategoryReport = (state) => state?.features?.categoryReport?.search

export default reducer


