import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_SUBMISSION_REFUSE } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_SUBMISSION_REFUSE
    }
};

export const submissionRefuseSlice = createSlice({
    name: 'submissionRefuse',
    initialState,
    reducers: {
        searchSubmissionRefuse: (state, action) => {
            state.search = action.payload;
        },
        searchSubmissionRefusePageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = submissionRefuseSlice;
export const { searchSubmissionRefuse, searchSubmissionRefusePageFirst } = actions

export const selectSearchSubmissionRefuse = (state) => state?.features?.submissionRefuse?.search

export default reducer


