import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_DIGITAL_CERTIFICATE } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_DIGITAL_CERTIFICATE
    }
};

export const digitalCertificateSlice = createSlice({
    name: 'digitalCertificate',
    initialState,
    reducers: {
        searchDigitalCertificate: (state, action) => {
            state.search = action.payload;
        },
        searchDigitalCertificatePageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = digitalCertificateSlice;
export const { searchDigitalCertificate, searchDigitalCertificatePageFirst } = actions

export const selectSearchDigitalCertificate = (state) => state?.features?.digitalCertificate?.search

export default reducer


