import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_CONTRACT } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_CONTRACT
    },
    tab:{
        activeTab:0,
        parentTab:true
    }
};

export const contractSlice = createSlice({
    name: 'contract',
    initialState,
    reducers: {
        searchContract: (state, action) => {
            state.search = action.payload;
        },
        searchContractPageFirst: (state, action) => {
            state.search.page = 1;
        },
        setTabContract: (state, action) => {
            state.tab = action.payload;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = contractSlice;
export const { searchContract, searchContractPageFirst, setTabContract } = actions

export const selectSearchContract = (state) => state?.features?.contract?.search
export const selectTabContract = (state) => state?.features?.contract?.tab


export default reducer


