import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";

export const EMPTY_DATA_SEARCH_IN_COMING_DOC = {
    text_search: "",
    is_use: null,
    partner_uuid: null,
    is_star: null,
    type_partner: null,
    user_uuid: null,
    partner_name_other: '',
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}
