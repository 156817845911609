import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";

export const EMPTY_DATA_SEARCH_SUBMISSION_CONFIRM = {
    text_search: "",
    status: null,
    procedure_uuid: null,
    status_numbered: null,
    check_file_mit: null,
    from_date: null,
    to_date: null,
    type: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}
