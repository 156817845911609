import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_CONTRACT_TYPE } from './constant/emptyData';

const initialState = {
    search:{
        ...EMPTY_DATA_SEARCH_CONTRACT_TYPE
    }
};

export const contractTypeSlice = createSlice({
    name: 'contractType',
    initialState,
    reducers: {
        searchContractType: (state, action) => {
            state.search = action.payload;
        },
        searchContractTypePageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})

const { reducer, actions } = contractTypeSlice;
// Action creators are generated for each case reducer function
export const { searchContractType, searchContractTypePageFirst } = actions

export const selectSearchContractType = (state) => state?.features?.contractType?.search

export default reducer


