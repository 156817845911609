import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_ROLE } from './constant/role';

const initialState = {
    search:{
        ...EMPTY_DATA_SEARCH_ROLE
    }
};

export const roleSlice = createSlice({
    name: 'role',
    initialState,
    reducers: {
        searchRole: (state, action) => {
            state.search = action.payload;

        },
        searchRolePageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})

const { reducer, actions } = roleSlice;
// Action creators are generated for each case reducer function
export const { searchRole, searchRolePageFirst } = actions

export const selectSearchRole = (state) => state?.features?.role?.search

export default reducer


