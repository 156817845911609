import React from 'react';
import 'react-app-polyfill/ie9';
import ReactDOM from 'react-dom';
import WrapApp from './WrapApp';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from "./serviceWorker";

ReactDOM.render(
    <React.StrictMode>
        <WrapApp />
    </React.StrictMode>
    ,
    document.getElementById("root")
);

reportWebVitals();

serviceWorker.unregister();


