
import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";

export const EMPTY_DATA_SEARCH_SEND_DOC = {
    text_search: "",
    status: null,
    partner_uuid: null,
    is_star: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}
