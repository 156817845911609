import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_USER } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_USER
    }
};

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        searchUser: (state, action) => {
            state.search = action.payload;
        },
        searchUserPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = userSlice;
export const { searchUser, searchUserPageFirst } = actions

export const selectSearchUser = (state) => state?.features?.user?.search

export default reducer


