import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_MAILING_HISTORY } from './constant/mailingHistory';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_MAILING_HISTORY
    }
};

export const mailingHistorySlice = createSlice({
    name: 'mailingHistory',
    initialState,
    reducers: {
        searchMailingHistory: (state, action) => {
            state.search = action.payload;
        },
        searchMailingHistoryPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = mailingHistorySlice;
export const { searchMailingHistory, searchMailingHistoryPageFirst } = actions

export const selectSearchMailingHistory = (state) => state?.features?.mailingHistory?.search

export default reducer


