import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_SEND_DOC } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_SEND_DOC
    }
};

export const sentDocSlice = createSlice({
    name: 'sentDoc',
    initialState,
    reducers: {
        searchSentDoc: (state, action) => {
            state.search = action.payload;
        },
        searchSentDocPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = sentDocSlice;
export const { searchSentDoc, searchSentDocPageFirst } = actions

export const selectSearchSentDoc = (state) => state?.features?.sentDoc?.search

export default reducer


