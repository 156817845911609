import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_APPROVAL_GROUP } from './constant/approveGroup';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_APPROVAL_GROUP
    }
};

export const approvalGroupSlice = createSlice({
    name: 'approvalGroup',
    initialState,
    reducers: {
        searchApprovalGroup: (state, action) => {
            state.search = action.payload;

        },
        searchApprovalGroupPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})

// Action creators are generated for each case reducer function
const { reducer, actions } = approvalGroupSlice;
export const { searchApprovalGroup, searchApprovalGroupPageFirst } = actions

export const selectSearchApprovalGroup = (state) => state?.features?.approvalGroup?.search

export default reducer


