import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_TYPE_PROCEDURE } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_TYPE_PROCEDURE
    }
};

export const typeProcedureSlice = createSlice({
    name: 'typeProcedure',
    initialState,
    reducers: {
        searchTypeProcedure: (state, action) => {
            state.search = action.payload;
        },
        searchTypeProcedurePageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = typeProcedureSlice;
export const { searchTypeProcedure, searchTypeProcedurePageFirst } = actions

export const selectSearchTypeProcedure = (state) => state?.features?.typeProcedure?.search

export default reducer


