import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_RELEASE_NOTE } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_RELEASE_NOTE
    }
};

export const releaseNoteSlice = createSlice({
    name: 'releaseNote',
    initialState,
    reducers: {
        searchReleaseNote: (state, action) => {
            state.search = action.payload;
        },
        searchReleaseNotePageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = releaseNoteSlice;
export const { searchReleaseNote, searchReleaseNotePageFirst } = actions

export const selectSearchReleaseNote = (state) => state?.features?.releaseNote?.search

export default reducer


