import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_REFERENCE_TEMPLATE_CONTRACT } from './constant/emptyData';

const initialState = {
    search:{
        ...EMPTY_DATA_SEARCH_REFERENCE_TEMPLATE_CONTRACT
    }
};

export const referenceTemplateContractSlice = createSlice({
    name: 'referenceTemplateContract',
    initialState,
    reducers: {
        searchReferenceTemplateContract: (state, action) => {
            state.search = action.payload;
        },
        searchReferenceTemplateContractPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})

const { reducer, actions } = referenceTemplateContractSlice;
// Action creators are generated for each case reducer function
export const { searchReferenceTemplateContract, searchReferenceTemplateContractPageFirst } = actions

export const selectSearchReferenceTemplateContract = (state) => state?.features?.referenceTemplateContract?.search

export default reducer


