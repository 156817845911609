import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_SUBMISSION } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_SUBMISSION
    },
    tab:{
        activeTab:0,
        parentTab:true
    }
};

export const submissionSlice = createSlice({
    name: 'submission',
    initialState,
    reducers: {
        searchSubmission: (state, action) => {
            state.search = action.payload;
        },
        searchSubmissionPageFirst: (state, action) => {
            state.search.page = 1;
        },
        setTabSubmission: (state, action) => {
            state.tab = action.payload;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = submissionSlice;
export const { searchSubmission, searchSubmissionPageFirst, setTabSubmission } = actions

export const selectSearchSubmission = (state) => state?.features?.submission?.search
export const selectTabSubmission = (state) => state?.features?.submission?.tab

export default reducer


