import store from 'app/store';
import { AppProvider } from 'context/AppProvider';
import { SocketProvider } from 'context/SocketProvider';
import PrimeReact from 'primereact/api';
import PageProgress from 'react-page-progress';
import { Provider } from 'react-redux';

import { KeycloakProvider } from '@react-keycloak/web';
import AppTranslations from 'translations/AppTranslations';
import keycloak from './keycloak';

import { BrowserRouter } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import EmptyPage from 'components/EmptyPage/EmptyPage';
// ** Styles
import 'primereact/resources/themes/bootstrap4-light-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import 'assets/style/layout.scss';
import { GoogleOAuthProvider } from '@react-oauth/google';

const windowEnvConfig = window['runConfig'];

const WrapApp = (props) => {
    const LazyApp = lazy(() => import('./App'));
    PrimeReact.ripple = true;
    PrimeReact.autoZIndex = true;

    const GOOGLE_CLIENT_ID = windowEnvConfig.REACT_APP_CLIENT_ID_GOOGLE

    const queryClient = new QueryClient();

    useEffect(() => {
        queryClient.setDefaultOptions({
            queries: {
                staleTime: 10000,
                keepPreviousData: true,
                // cacheTime: 0,
                refetchOnWindowFocus: false,
                retry: false,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="WrapApp">
            <ErrorBoundary>
                <BrowserRouter>
                    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
                        <AppTranslations>
                            <SocketProvider>
                                <Provider store={store}>
                                    <QueryClientProvider client={queryClient}>
                                        <AppProvider>
                                            <KeycloakProvider keycloak={keycloak}>
                                                <PageProgress
                                                    color={'var(--primary)'}
                                                    height={4}
                                                    className="PageProgress"
                                                />
                                                <Suspense fallback={<EmptyPage />}>
                                                    <LazyApp />
                                                    <ReactQueryDevtools initialIsOpen />
                                                </Suspense>
                                            </KeycloakProvider>
                                        </AppProvider>
                                    </QueryClientProvider>
                                </Provider>
                            </SocketProvider>
                        </AppTranslations>
                    </GoogleOAuthProvider>
                </BrowserRouter>
            </ErrorBoundary>
        </div>
    );
};
export default WrapApp;
