import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_CONTRACT_REFUSE } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_CONTRACT_REFUSE
    }
};

export const contractRefuseSlice = createSlice({
    name: 'contractRefuse',
    initialState,
    reducers: {
        searchContractRefuse: (state, action) => {
            state.search = action.payload;
        },
        searchContractRefusePageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = contractRefuseSlice;
export const { searchContractRefuse, searchContractRefusePageFirst } = actions

export const selectSearchContractRefuse = (state) => state?.features?.contractRefuse?.search

export default reducer


