import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_CONTRACT_CONFIRMED } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_CONTRACT_CONFIRMED
    }
};

export const contractConfirmedSlice = createSlice({
    name: 'contractConfirmed',
    initialState,
    reducers: {
        searchContractConfirmed: (state, action) => {
            state.search = action.payload;
        },
        searchContractConfirmedPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = contractConfirmedSlice;
export const { searchContractConfirmed, searchContractConfirmedPageFirst } = actions

export const selectSearchContractConfirmed = (state) => state?.features?.contractConfirmed?.search

export default reducer


