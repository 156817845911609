import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_IN_COMING_DOC } from './constant/emptyData';

const initialState = {
    search:{
        ...EMPTY_DATA_SEARCH_IN_COMING_DOC
    }
};

export const incomingDocSlice = createSlice({
    name: 'incomingDoc',
    initialState,
    reducers: {
        searchIncomingDoc: (state, action) => {
            state.search = action.payload;
        },
        searchIncomingDocPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})

const { reducer, actions } = incomingDocSlice;
// Action creators are generated for each case reducer function
export const { searchIncomingDoc, searchIncomingDocPageFirst } = actions

export const selectSearchIncomingDoc = (state) => state?.features?.incomingDoc?.search

export default reducer


