import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_REFERENCE_TEMPLATE_TEXT } from './constant/emptyData';

const initialState = {
    search:{
        ...EMPTY_DATA_SEARCH_REFERENCE_TEMPLATE_TEXT
    }
};

export const referenceTemplateTextSlice = createSlice({
    name: 'referenceTemplateText',
    initialState,
    reducers: {
        searchReferenceTemplateText: (state, action) => {
            state.search = action.payload;
        },
        searchReferenceTemplateTextPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})

const { reducer, actions } = referenceTemplateTextSlice;
// Action creators are generated for each case reducer function
export const { searchReferenceTemplateText, searchReferenceTemplateTextPageFirst } = actions

export const selectSearchReferenceTemplateText = (state) => state?.features?.referenceTemplateText?.search

export default reducer


