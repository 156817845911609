import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_PARTNER } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_PARTNER
    }
};

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        searchPartner: (state, action) => {
            state.search = action.payload;
        },
        searchPartnerPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = partnerSlice;
export const { searchPartner, searchPartnerPageFirst } = actions

export const selectSearchPartner = (state) => state?.features?.partner?.search

export default reducer


