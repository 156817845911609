import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_PROCEDURE } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_PROCEDURE
    }
};

export const procedureSlice = createSlice({
    name: 'procedure',
    initialState,
    reducers: {
        searchProcedure: (state, action) => {
            state.search = action.payload;
        },
        searchProcedurePageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = procedureSlice;
export const { searchProcedure, searchProcedurePageFirst } = actions

export const selectSearchProcedure = (state) => state?.features?.procedure?.search

export default reducer


