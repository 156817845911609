import { SYSTEM_APP_SIGN } from 'constants/global';
import getSystem from 'helpers/getSystem';
import Keycloak from 'keycloak-js';
const windowEnvConfig = window['runConfig'];

// const keycloakConfig = {
//     // url: 'https://stg-accounts.xcyber.vn/auth',
//     url: 'http://10.30.1.40:8080/auth/',
//     realm: 'cyberid',
//     clientId: 'cyberdev',
// }

const BASE_URL_KC = windowEnvConfig.REACT_APP_KC_URL;
const REAL_KC = windowEnvConfig.REACT_APP_KC_REALM;
const CLIENT_ID_KC = getSystem() === SYSTEM_APP_SIGN
    ? windowEnvConfig.REACT_APP_KC_CLIENT_ID
    : windowEnvConfig.REACT_APP_KC_CLIENT_ID_CYBERCONTRACT;

const keycloakConfig = {
    url: BASE_URL_KC,
    realm: REAL_KC,
    clientId: CLIENT_ID_KC,
};
const keycloak = new Keycloak(keycloakConfig);
export default keycloak;
