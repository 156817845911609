import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";
import { FormattedMessage } from "react-intl";

export const EMPTY_DATA_SEARCH_CONTRACT_DOCUMENT = {
    text_search: '',
    status: null,
    contract_uuid: null,
    type: null,
    from_date: null,
    to_date: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}


export const ITEM_STEP_CONTRACT_DOCUMENT = [
    {
        label: <FormattedMessage id="ContractDocument.step1" />,
        code: 1
    },
    {
        label: <FormattedMessage id="participating-parties" />,
        code: 2
    },
    {
        label: <FormattedMessage id="set-position" />,
        code: 3
    },
    {
        label: <FormattedMessage id="ContractDocument.step4" />,
        code: 4
    },
];
