import { APP_ECONTRACT, SYSTEM_APP_ECONTRACT, SYSTEM_APP_SIGN, TEST_SYSTEM } from 'constants/global';

const getSystem = () => {
    let system = TEST_SYSTEM;
    if (window.location.host !== '127.0.0.1:5173') {
        system = window.location.host.includes(APP_ECONTRACT) ? SYSTEM_APP_ECONTRACT : SYSTEM_APP_SIGN;
    }
    return system;
};

export default getSystem;
