import { common } from './vi/common';
import UploadFileOtherVi from 'components/upload-file-other/translate/vi.json';
import UploadFileVi from 'components/upload-file/translate/vi.json';
import StatusTimeLineDialogVi from 'components/time-line-custom/translate/vi.json';
import CountdownCsVi from 'components/CountdownCs/translate/vi.json';

import CategorySentDocVi from 'features/category-sent-doc/translate/vi.json';
import CategoryIncomingDocVi from 'features/category-incoming-doc/translate/vi.json';
import PersonalDocumentVi from 'features/personal-document/translate/vi.json';
import PartnerVi from 'features/partner/translate/vi.json';
import CategoryProcedureVi from 'features/category-procedure/translate/vi.json';
import SignTemplateVi from 'features/sign-template/translate/vi.json';
import ConfigDigitalCertificateVi from 'features/config-digital-certificate/translate/vi.json';
import ConfigStorageVi from 'features/config-storage/translate/vi.json';
import ConfigVi from 'features/config/translate/vi.json';
import CategoryRealeaseNoteVi from 'features/category-release-note/translate/vi.json';
import HomePageVi from 'features/home-page/translate/vi.json';
import QuickWizardVi from 'features/Quick-Wizard/translate/vi.json';
import CategorySubmissionVi from 'features/category-submission/translate/vi.json';
import ContractManagementConfirmVi from 'features/contract/contract-management-confirm/translate/vi.json';
import UsersVi from 'features/users/translate/vi.json';
import PageErrVi from 'components/errors/translate/vi.json';
import HomeVi from 'features/home/translate/vi.json';
import ContractVi from 'features/contract/contract-management/translate/vi.json';
import SynthesisReportVi from 'features/category-report/synthesis-report/translate/vi.json';
import AuthorityVi from 'features/authority-manager/translate/vi.json';
import typeContractVi from 'features/category-contract-type/translate/vi.json';
import categorySubmissionConfirmVi from 'features/category-submission-confirm/translate/vi.json';
import EmailTemplateVi from 'features/category-mail/translate/vi.json';
import CategoryApprovalGroupVi from 'features/category-approval-group/translate/vi.json';

import ContractDocumentVi from 'features/contract-document/contract-document-manager/translate/vi.json';
import ContractDocumentCfVi from 'features/contract-document/contract-document-confirm/translate/vi.json';
import ContractLotVi from 'features/contract/contract-management-lot/translate/vi.json';
import SignVi from './vi/sign.json';

import ConstantsVi from 'constants/translate/vi.json';

import CategoryDocumentVi from 'features/category-document/document-type/translate/vi.json';
import ReferenceTemplateTextVi from 'features/reference-template/reference-template-text/translate/vi.json';
import ReferenceTemplateContractVi from 'features/reference-template/reference-template-contract/translate/vi.json';
import OrganizationTreeVi from 'features/organization-tree/translate/vi.json';


const Vietnamese = {
    ...common,
    ...CategorySentDocVi,
    ...CategoryIncomingDocVi,
    ...UploadFileOtherVi,
    ...UploadFileVi,
    ...PersonalDocumentVi,
    ...CategoryDocumentVi,
    ...PartnerVi,
    ...CategoryProcedureVi,
    ...SignTemplateVi,
    ...ConfigDigitalCertificateVi,
    ...ConfigStorageVi,
    ...ConfigVi,
    ...CategoryRealeaseNoteVi,
    ...HomePageVi,
    ...QuickWizardVi,
    ...CategorySubmissionVi,
    ...ContractManagementConfirmVi,
    ...StatusTimeLineDialogVi,
    ...ConstantsVi,
    ...UsersVi,
    ...PageErrVi,
    ...HomeVi,
    ...ContractVi,
    ...SynthesisReportVi,
    ...AuthorityVi,
    ...typeContractVi,
    ...categorySubmissionConfirmVi,
    ...CountdownCsVi,
    ...EmailTemplateVi,
    ...CategoryApprovalGroupVi,
    ...ContractDocumentVi,
    ...ContractDocumentCfVi,
    ...SignVi,
    ...ContractLotVi,
    ...ReferenceTemplateTextVi,
    ...ReferenceTemplateContractVi,
    ...OrganizationTreeVi
};
export default Vietnamese;
