import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";
import { statusSubmission } from "constants/submission/statusSubmission";

export const EMPTY_DATA_SEARCH_SUBMISSION = {
    text_search: '',
    status: null,
    procedure_uuid: null,
    status_numbered: null,
    // check_file_mit: null,
    from_date: null,
    to_date: null,
    type: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}

export const STATUS_CAN_STOP_EFFECT = [statusSubmission.DONE_STATUS];
export const STATUS_CAN_CF_STOP_EFFECT = [statusSubmission.WAITING_APPROVE_EXPIRE_STATUS];
export const STATUS_CAN_REASON_STOP_EFFECT = [
    statusSubmission.WAITING_APPROVE_EXPIRE_STATUS,
    statusSubmission.EXPIRE_STATUS,
];

export const TYPE_SUBMISSION = {
    BY_PROCEDURE:1,
    QUICK_SUBMIT:2,
    LOT:3
}




