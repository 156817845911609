import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";

export const EMPTY_DATA_SEARCH_PROCEDURE = {
    text_search: "",
    numbered_text: null,
    type_uuid: null,
    status: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}
