import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";
import { ACTIVE_STATUS } from "constants/status";

export const EMPTY_DATA_APPROVAL_GROUP = {
    name: "",
    list_uuid: null,
    status: ACTIVE_STATUS,
};



export const EMPTY_DATA_SEARCH_APPROVAL_GROUP = {
    text_search: "",
    status: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
};

