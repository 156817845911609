import { createSlice } from '@reduxjs/toolkit';
import { APP_SIGN } from 'constants/global';

const initialState = {
    isAuth: false,
    userData: null,
    dataError401: {
        messeger: '',
    },
    app: APP_SIGN,
    logo:{
        src:'',
        height:36
    },
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setAuthData: (state, action) => {
            return { ...state, userData: action.payload, isAuth: true };
        },
        setAppAuthData: (state, action) => {
            state.app = action.payload;
        },
        setLogoAuthData: (state, action) => {
            state.logo = action.payload;
        },
        unsetAuthData: (state) => {
            return { ...initialState };
        },
        authData401: (state, action) => {
            return { ...state, isAuth: false, dataError401: action.payload };
        },
    },
});

// Action creators are generated for each case reducer function
export const { setAuthData,setAppAuthData, unsetAuthData, authData401,setLogoAuthData } = authSlice.actions;

export const selectUser = (state) => state?.auth?.userData;
export const selectAuth = (state) => state?.auth;

export default authSlice.reducer;
