import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";

export const EMPTY_DATA_SEARCH_USER = {
    text_search: "",
    type: null,
    status: null,
    role: null,
    date: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}
