import { combineReducers } from 'redux';
import userSlice from 'features/users/userSlice';
import roleSlice from 'features/roles/roleSlice';
import approvalGroupSlice from 'features/category-approval-group/approvalGroupSlice';
import mailingHistorySlice from 'features/mailing-history/mailingHistorySlice';
import mailSlice from 'features/category-mail/mailSlice';
import authoritySlice from 'features/authority-manager/authoritySlice';
import contractTypeSlice from 'features/category-contract-type/contractTypeSlice';
import incomingDocSlice from 'features/category-incoming-doc/incomingDocSlice';
import positionSlice from 'features/category-position/positionSlice';
import procedureSlice from 'features/category-procedure/procedureSlice';
import submissionRefuseSlice from 'features/category-refuse/submissionRefuseSlice';
import releaseNoteSlice from 'features/category-release-note/releaseNoteSlice';
import categoryReportSlice from 'features/category-report/synthesis-report/categoryReportSlice';
import sentDocSlice from 'features/category-sent-doc/sentDocSlice';
import submissionSlice from 'features/category-submission/submissionSlice';
import submissionConfirmSlice from 'features/category-submission-confirm/submissionConfirmSlice';
import submissionConfirmedSlice from 'features/category-submission-confirmed/submissionConfirmedSlice';
import typeProcedureSlice from 'features/category-type-procedure/typeProcedureSlice';
import digitalCertificateSlice from 'features/config-digital-certificate/digitalCertificateSlice';
import contractComplaintsSlice from 'features/contract-complaints/contractComplaintsSlice';
import contractSlice from 'features/contract/contract-management/contractSlice';
import contractConfirmSlice from 'features/contract/contract-management-confirm/contractConfirmSlice';
import partnerSlice from 'features/partner/partnerSlice';
import signTemplateSlice from 'features/sign-template/signTemplateSlice';
import contractDocumentSlice from 'features/contract-document/contract-document-manager/contractDocumentSlice';
import contractConfirmedSlice from 'features/contract/contract-management-confirmed/contractConfirmedSlice';
import contractRefuseSlice from 'features/contract/contract-management-refuse/contractRefuseSlice';
import documentTypeSlice from 'features/category-document-type/document-type/documentTypeSlice';
import documentSlice from 'features/category-document/document-type/documentSlice';
import referenceTemplateTextSlice from 'features/reference-template/reference-template-text/referenceTemplateTextSlice';
import referenceTemplateContractSlice from 'features/reference-template/reference-template-contract/referenceTemplateContractSlice';

const featuresReducer = combineReducers({
    user: userSlice,
    role: roleSlice,
    approvalGroup: approvalGroupSlice,
    mailingHistory: mailingHistorySlice,
    mail: mailSlice,
    authority: authoritySlice,
    contractType: contractTypeSlice,
    document: documentSlice,
    incomingDoc: incomingDocSlice,
    position: positionSlice,
    procedure: procedureSlice,
    submissionRefuse: submissionRefuseSlice,
    releaseNote: releaseNoteSlice,
    categoryReport: categoryReportSlice,
    sentDoc: sentDocSlice,
    submission: submissionSlice,
    submissionConfirm: submissionConfirmSlice,
    submissionConfirmed: submissionConfirmedSlice,
    typeProcedure: typeProcedureSlice,
    digitalCertificate: digitalCertificateSlice,
    contractComplaints: contractComplaintsSlice,
    contract: contractSlice,
    contractConfirm: contractConfirmSlice,
    partner: partnerSlice,
    signTemplate: signTemplateSlice,
    contractDocument: contractDocumentSlice,
    contractConfirmed: contractConfirmedSlice,
    contractRefuse: contractRefuseSlice,
    documentType: documentTypeSlice,
    referenceTemplateText: referenceTemplateTextSlice,
    referenceTemplateContract: referenceTemplateContractSlice,
});

export default featuresReducer;
