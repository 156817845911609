import { PAGE_DEFAULT, ROW_DEFAULT } from 'constants/global';

export const EMPTY_DATA_SEARCH_CONTRACT_CONFIRM = {
    text_search: '',
    status: null,
    contract_type_uuid: null,
    check_file_mit: null,
    from_date: null,
    to_date: null,
    created_by: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
};
