import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_MAIL } from './constant/mail';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_MAIL
    }
};

export const mailSlice = createSlice({
    name: 'mail',
    initialState,
    reducers: {
        searchMail: (state, action) => {
            state.search = action.payload;
        },
        searchMailPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = mailSlice;
export const { searchMail, searchMailPageFirst } = actions

export const selectSearchMail = (state) => state?.features?.mail?.search

export default reducer


