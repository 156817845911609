import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_DOCUMENT } from './constant/emptyData';

const initialState = {
    search:{
        ...EMPTY_DATA_SEARCH_DOCUMENT
    }
};

export const documentSlice = createSlice({
    name: 'document',
    initialState,
    reducers: {
        searchDocument: (state, action) => {
            state.search = action.payload;

        },
        searchDocumentPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})

const { reducer, actions } = documentSlice;
// Action creators are generated for each case reducer function
export const { searchDocument, searchDocumentPageFirst } = actions

export const selectSearchDocument = (state) => state?.features?.document?.search

export default reducer


