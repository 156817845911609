import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";

export const EMPTY_DATA_SEARCH_TYPE_PROCEDURE = {
    text_search: '',
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}

export const EMPTY_DATA_TYPE_PROCEDURE = {
    name: "",
    description: "",
};
