import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_SUBMISSION_CONFIRMED } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_SUBMISSION_CONFIRMED
    }
};

export const submissionConfirmedSlice = createSlice({
    name: 'submissionConfirmed',
    initialState,
    reducers: {
        searchSubmissionConfirmed: (state, action) => {
            state.search = action.payload;
        },
        searchSubmissionConfirmedPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = submissionConfirmedSlice;
export const { searchSubmissionConfirmed, searchSubmissionConfirmedPageFirst } = actions

export const selectSearchSubmissionConfirmed = (state) => state?.features?.submissionConfirmed?.search

export default reducer


