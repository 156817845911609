import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_POSITION } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_POSITION
    }
};

export const positionSlice = createSlice({
    name: 'position',
    initialState,
    reducers: {
        searchPosition: (state, action) => {
            state.search = action.payload;
        },
        searchPositionPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = positionSlice;
export const { searchPosition, searchPositionPageFirst } = actions

export const selectSearchPosition = (state) => state?.features?.position?.search

export default reducer


