import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_AUTHORITY } from './constant/emptyData';

const initialState = {
    search:{
        ...EMPTY_DATA_SEARCH_AUTHORITY
    }
};

export const authoritySlice = createSlice({
    name: 'authority',
    initialState,
    reducers: {
        searchAuthority: (state, action) => {
            state.search = action.payload;
        },
        searchAuthorityPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})

const { reducer, actions } = authoritySlice;
// Action creators are generated for each case reducer function
export const { searchAuthority, searchAuthorityPageFirst } = actions

export const selectSearchAuthority = (state) => state?.features?.authority?.search

export default reducer


