import { common } from './en/common'

import UploadFileOtherEn from 'components/upload-file-other/translate/en.json';
import UploadFileEn from 'components/upload-file/translate/en.json';
import StatusTimeLineDialogEn from 'components/time-line-custom/translate/en.json';
import CountdownCsEn from 'components/CountdownCs/translate/en.json';

import CategorySentDocEn from 'features/category-sent-doc/translate/en.json';
import CategoryIncomingDocEn from 'features/category-incoming-doc/translate/en.json';
import PersonalDocumentEn from 'features/personal-document/translate/en.json'
import PartnerEn from 'features/partner/translate/en.json'
import CategoryProcedureEn from 'features/category-procedure/translate/en.json';
import SignTemplateEn from 'features/sign-template/translate/en.json'
import ConfigDigitalCertificateEn from 'features/config-digital-certificate/translate/en.json';
import ConfigStorageEn from 'features/config-storage/translate/en.json';
import ConfigEn from 'features/config/translate/en.json';
import CategoryRealeaseNoteEn from 'features/category-release-note/translate/en.json'
import HomePageEn from 'features/home-page/translate/en.json';
import QuickWizardEn from 'features/Quick-Wizard/translate/en.json';
import CategorySubmissionEn from 'features/category-submission/translate/en.json';
import ContractManagementConfirmEn from 'features/contract/contract-management-confirm/translate/en.json';
import UsersEn from 'features/users/translate/en.json';
import PageErrEn from 'components/errors/translate/en.json';
import HomeEn from 'features/home/translate/en.json';
import ContractEn from 'features/contract/contract-management/translate/en.json';
import SynthesisReportEn from 'features/category-report/synthesis-report/translate/en.json';
import AuthorityEn from 'features/authority-manager/translate/en.json';
import typeContractEn from 'features/category-contract-type/translate/en.json';
import categorySubmissionConfirmEn from 'features/category-submission-confirm/translate/en.json';
import EmailTemplateEn from 'features/category-mail/translate/en.json';
import CategoryApprovalGroupEn from 'features/category-approval-group/translate/en.json';
import ContractDocumentEn from 'features/contract-document/contract-document-manager/translate/en.json';
import ContractDocumentCfEn from 'features/contract-document/contract-document-confirm/translate/en.json';
import ContractLotEn from 'features/contract/contract-management-lot/translate/en.json';
import SignEn from './en/sign.json';
import ConstantsEn from 'constants/translate/en.json'

import CategoryDocumentEn from 'features/category-document/document-type/translate/en.json'
import ReferenceTemplateTextEn from 'features/reference-template/reference-template-text/translate/en.json';
import ReferenceTemplateContractEn from 'features/reference-template/reference-template-contract/translate/en.json';
import OrganizationTreeEn from 'features/organization-tree/translate/en.json';

const English = {
    ...common,
    ...CategorySentDocEn,
    ...CategoryIncomingDocEn,
    ...UploadFileOtherEn,
    ...UploadFileEn,
    ...PersonalDocumentEn,
    ...CategoryDocumentEn,
    ...PartnerEn,
    ...CategoryProcedureEn,
    ...SignTemplateEn,
    ...ConfigDigitalCertificateEn,
    ...ConfigStorageEn,
    ...ConfigEn,
    ...CategoryRealeaseNoteEn,
    ...HomePageEn,
    ...QuickWizardEn,
    ...CategorySubmissionEn,
    ...ContractManagementConfirmEn,
    ...StatusTimeLineDialogEn,
    ...ConstantsEn,
    ...UsersEn,
    ...PageErrEn,
    ...HomeEn,
    ...ContractEn,
    ...SynthesisReportEn,
    ...AuthorityEn,
    ...typeContractEn,
    ...categorySubmissionConfirmEn,
    ...CountdownCsEn,
    ...EmailTemplateEn,
    ...CategoryApprovalGroupEn,
    ...ContractDocumentEn,
    ...ContractDocumentCfEn,
    ...SignEn,
    ...ContractLotEn,
    ...ReferenceTemplateTextEn,
    ...ReferenceTemplateContractEn,
    ...OrganizationTreeEn
}
export default English
