import { PAGE_DEFAULT, ROW_DEFAULT } from 'constants/global';
import { FormattedMessage } from 'react-intl';


export const EMPTY_DATA_SEARCH_CONTRACT = {
    text_search: '',
    status: null,
    contract_type_uuid: null,
    check_file_mit: null,
    from_date: null,
    to_date: null,
    created_by: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
};


export const ARR_STATUS_CONTRACT = [
    { code: 1, name: <FormattedMessage id="draft" />, color: 'secondary' },
    { code: 2, name: <FormattedMessage id="waiting-sign" />, color: 'warning' },
    { code: 3, name: <FormattedMessage id="waiting-approve" />, color: 'warning' },
    { code: 4, name: <FormattedMessage id="refuse-sign" />, color: 'danger' },
    { code: 5, name: <FormattedMessage id="refuse-review" />, color: 'danger' },
    { code: 6, name: <FormattedMessage id="out-of-date" />, color: 'danger' },
    { code: 7, name: <FormattedMessage id="expiring" />, color: 'success' },
    { code: 8, name: <FormattedMessage id="expire" />, color: 'danger' },
    { code: 10, name: <FormattedMessage id="cancelled" />, color: 'danger' },
    { code: 11, name: <FormattedMessage id="common.process_create_lot" />, color: 'warning' },
    { code: 12, name: <FormattedMessage id="common.process_sign" />, color: 'warning' },
];

export const ARR_STATUS_CONTRACT_STEP = [
    { code: 1, name: <FormattedMessage id="not-your-turn" />, color: 'secondary' },
    { code: 2, name: <FormattedMessage id="waiting-sign" />, color: 'warning' },
    { code: 3, name: <FormattedMessage id="waiting-approve" />, color: 'warning' },
    { code: 4, name: <FormattedMessage id="refuse-sign" />, color: 'danger' },
    { code: 5, name: <FormattedMessage id="refuse-review" />, color: 'danger' },
    { code: 6, name: <FormattedMessage id="out-of-date" />, color: 'danger' },
    { code: 7, name: <FormattedMessage id="complete" />, color: 'success' },
    { code: 8, name: <FormattedMessage id="cancelled" />, color: 'danger' },
    { code: 9, name: <FormattedMessage id="common.process_sign" />, color: 'warning' },
];

export const TYPE_CONTRACT = {
    NORMAL: 1,
    LOT: 2,
};
