import Loader from 'components/loading/Loader';
import React from 'react';

const EmptyPage = () => {
    return (
        <div className="EmptyPage ">
            <div className="p-d-flex p-ai-center p-jc-center p-as-center vh--100">
            <Loader />
            </div>
        </div>
    )
}

export default EmptyPage
