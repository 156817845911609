import { createSlice } from '@reduxjs/toolkit';
import { EMPTY_DATA_SEARCH_SUBMISSION_CONFIRM } from './constant/emptyData';

const initialState = {
    search: {
        ...EMPTY_DATA_SEARCH_SUBMISSION_CONFIRM
    }
};

export const submissionConfirmSlice = createSlice({
    name: 'submissionConfirm',
    initialState,
    reducers: {
        searchSubmissionConfirm: (state, action) => {
            state.search = action.payload;
        },
        searchSubmissionConfirmPageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})


// Action creators are generated for each case reducer function
const { reducer, actions } = submissionConfirmSlice;
export const { searchSubmissionConfirm, searchSubmissionConfirmPageFirst } = actions

export const selectSearchSubmissionConfirm = (state) => state?.features?.submissionConfirm?.search

export default reducer


