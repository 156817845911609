import { createSlice } from '@reduxjs/toolkit'
import { EMPTY_DATA_SEARCH_DOCUMENT_TYPE } from './constant/emptyData';

const initialState = {
    search:{
        ...EMPTY_DATA_SEARCH_DOCUMENT_TYPE
    }
};

export const documentTypeSlice = createSlice({
    name: 'documentType',
    initialState,
    reducers: {
        searchDocumentType: (state, action) => {
            state.search = action.payload;
        },
        searchDocumentTypePageFirst: (state, action) => {
            state.search.page = 1;
        },
    },
})

const { reducer, actions } = documentTypeSlice;
// Action creators are generated for each case reducer function
export const { searchDocumentType, searchDocumentTypePageFirst } = actions

export const selectSearchDocumentType = (state) => state?.features?.documentType?.search

export default reducer


