import { PAGE_DEFAULT, ROW_DEFAULT } from "constants/global";

export const EMPTY_DATA_SEARCH_AUTHORITY = {
    list_procedure: [],
    list_type_contract: [],
    status: null,
    from_date: null,
    to_date: null,
    row: ROW_DEFAULT,
    page: PAGE_DEFAULT,
}
